<template>
  <FormKit
    v-model="checked"
    type="checkbox"
    name="terms"
    validation="required|accepted"
    :validation-messages="{
      required: $texts('requiredField', 'Dieses Feld ist erforderlich.'),
      accepted: $texts(
        'termsAndConditionCheck',
        'Bitte akzeptieren Sie die Bedingungen, um fortzufahren.',
      ),
    }"
  >
    <template #label="context">
      <span :class="context.classes.label" v-html="termsAndConditions"> </span>
    </template>
  </FormKit>
</template>

<script lang="ts" setup>
import { useGlobalConfig } from '~/stores/globalConfig'

const props = defineProps<{
  withoutLink?: boolean
}>()

const checked = defineModel<boolean>()

const globalConfig = useGlobalConfig()
const { $texts } = useNuxtApp()

const termsAndConditions = computed(() => {
  const text = $texts(
    'termsOfServiceCheckbox',
    'Ich stimme den $Nutzungsbedingungen$ zu.',
  )

  if (props.withoutLink || !globalConfig.termsAndConditionsLink) {
    // Should be rendered without a link.
    return text.replaceAll('$', '')
  }

  // Wrap the link text in a link.
  return text.replace(
    /(\$(.*)\$)/g,
    `<a target="_blank" href="${globalConfig.termsAndConditionsLink}?no-tos-check=true">$2</a>`,
  )
})
</script>
